import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const List = makeShortcode("List");
const Link = makeShortcode("Link");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "curated-material---game-discoverability",
      "style": {
        "position": "relative"
      }
    }}>{`Curated Material - Game Discoverability`}<a parentName="h1" {...{
        "href": "#curated-material---game-discoverability",
        "aria-label": "curated material   game discoverability permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <List mdxType="List">
      <Link to="https://steamspy.com/" mdxType="Link">
        <h4 {...{
          "id": "steamspy",
          "style": {
            "position": "relative"
          }
        }}>{`Steamspy`}<a parentName="h4" {...{
            "href": "#steamspy",
            "aria-label": "steamspy permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`A third-party service that analyzes trends and historical data on Steam. Also a good case study on how data asymmetry affects game developers, and how we can still find ways around the opacity of data.`}</p>
      </Link>
      <Link to="https://store.steampowered.com/labs/" mdxType="Link">
        <h4 {...{
          "id": "steam-labs",
          "style": {
            "position": "relative"
          }
        }}>{`Steam Labs`}<a parentName="h4" {...{
            "href": "#steam-labs",
            "aria-label": "steam labs permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`Steam is arguably the most open platform out of all the app stores, and they regularly publish their efforts on how to improve game discoverability via these Steam Lab experiments. Some of their earlier experiments revolved around `}<a parentName="p" {...{
            "href": "https://store.steampowered.com/curators/"
          }}>{`Curators`}</a>{`. `}</p>
      </Link>
      <Link to="https://trello.com/b/GXLc34hk/epic-games-store-roadmap" mdxType="Link">
        <h4 {...{
          "id": "epic-games-store-roadmap",
          "style": {
            "position": "relative"
          }
        }}>{`Epic Games Store Roadmap`}<a parentName="h4" {...{
            "href": "#epic-games-store-roadmap",
            "aria-label": "epic games store roadmap permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`The Epic Store is the second most popular PC-based app store. This public feature roadmap gives us a glimpse into what a platform considers as priorities when building frontends for discovery. Epic has a different take on curation and discovery, opting to do it via `}<a parentName="p" {...{
            "href": "https://www.epicgames.com/affiliate/en-US/overview"
          }}>{`Creators`}</a>{` that they directly incentivize.`}</p>
        <Image src="/images/gaming-img/epiccreator.jpg" alt="Epic Creators" mdxType="Image" />
      </Link>
    </List>
    <h2 {...{
      "id": "more-signals-v-noise",
      "style": {
        "position": "relative"
      }
    }}>{`More Signals v. Noise`}<a parentName="h2" {...{
        "href": "#more-signals-v-noise",
        "aria-label": "more signals v noise permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`It's good practice to take a look at platforms and communities where we could learn how to optimize for discovery. Below are some recommended sources.`}</p>
    <List mdxType="List">
      <Link to="https://www.twitch.tv/directory?sort=VIEWER_COUNT" mdxType="Link">
        <p>{`Most Viewed Games on Twitch`}</p>
      </Link>
      <Link to="https://www.reddit.com/r/IndieGaming/" mdxType="Link">
        <p>{`r/IndieGaming`}</p>
      </Link>
      <Link to="https://itch.io/devlogs" mdxType="Link">
        <p>{`Itch.io Devlogs`}</p>
      </Link>
      <Link to="https://steamcommunity.com/games/593110/announcements" mdxType="Link">
        <p>{`Steam News`}</p>
      </Link>
      <Link to="https://sensortower.com/blog" mdxType="Link">
        <p>{`Sensortower`}</p>
      </Link>
      <Link to="https://gamedevjs.com/" mdxType="Link">
        <p>{`Gamedev.js`}</p>
      </Link>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      